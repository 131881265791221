<div class="d-flex h-100 flex-column">
  <div>
    <mat-toolbar>
      <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>menu</mat-icon>
        </button> -->
      <span>My App</span>
      <span class="spacer"></span>
      <div>
        <b>PRO</b><small>ject</small> - <b>WO</b><small>rk</small> - <b>DO</b
        ><small>ne</small>

        <mat-icon>
          notifications
          <!-- notifications_active -->
        </mat-icon>
      </div>
      <!-- class="d-md-none"  -->
      <!-- <button
        mat-icon-button
        aria-label="Main Menu"
        (click)="toggleMobileMenu()"
        class="d-block d-md-none"
      >
        <mat-icon>menu</mat-icon>
      </button> -->
      <button
        mat-icon-button
        aria-label="Main Menu"
        (click)="mobileMenu.toggle()"
        class="d-block d-md-none"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <!--
          <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
          <mat-icon>share</mat-icon>
        </button> -->
      <!-- "top" -->
    </mat-toolbar>
  </div>
  <div class="flex-grow-1 overflow-y-hidden">
    <mat-sidenav-container class="h-100">
      <mat-sidenav
        #sidenavBar
        mode="side"
        opened
        class="side-bar h-100 overflow-y-auto ps-3 pt-3 d-none d-md-block"
      >
        <!-- d-none d-md-block -->
        <app-side-menu></app-side-menu>
      </mat-sidenav>
      <!-- mode="side" -->
      <!-- [opened]="mobileMenuIsOpen" -->
      <mat-sidenav
        #mobileMenu
        position="end"
        from="top"
        mode="over"
        class="mobile-menu h-100 overflow-y-auto ps-3 pt-3 w-100 d-block d-md-none"
      >
        <!-- d-block d-md-none -->
        <app-side-menu (clickOnVoice)="clickOnVoice()"></app-side-menu>
      </mat-sidenav>
      <mat-sidenav-content class="h-100 overflow-y-auto">
        <div class="d-flex h-100 flex-column">
          <div class="main-container px-3 d-flex h-100 flex-column">
            <!-- <div class="row"> -->
            <!-- <div class="col"> -->
            <div class="h-100">
              <!-- <router-outlet></router-outlet> -->
              <ng-content></ng-content>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
